<template>
  <div>
    <div class="pc">
      <template v-if="ordering">
        <div class="lp-divider-main" style="padding-top:96px"></div>
        <div class="padding-top-16">
          <div class="body0-bold main">추가 수정 요청 주문서</div>
        </div>
        <div class="body2 sub3 margin-top-8">기본 스킨에서 수정하고 싶은 기능 / 디자인에 대해 입력해주세요.</div>
        <div class="lp-divider-gray2 padding-top-16"></div>
      </template>
      <div class="margin-top-20 margin-bottom-8 subtitle5 main">수정 요청 종류*</div>
      <div class="dropdown unselect sub" :class="{'dropdown-open':vDrop}" @click="vDrop = !vDrop">
        <div class="flex-between">
          <div class="dropdown-selected-item">{{ selectedLabel }}</div>
          <i class="material-icons">keyboard_arrow_{{ vDrop ? 'up' : 'down' }}</i>
        </div>
        <div class="dropdown-list" v-if="vDrop">
          <div class="dropdown-item" v-for="(item,idx) in categories" :key="`drop-${idx}`"
               @click.stop.prevent="clickItem(item)">{{ item.label }}</div>
        </div>
      </div>
      <div class="margin-top-20 margin-bottom-8 subtitle5 main">수정 요청 내용*</div>
      <textarea v-model="value.content" @keyup="e => value.content = e.target.value"></textarea>
      <div class="margin-top-20 margin-bottom-8 subtitle5 main">참고 파일</div>
      <input-file class="pc" style="margin-bottom:16px" :value.sync="value.imgs"></input-file>

      <div v-if="ordering" class="box-warning">
        <svg-icon icon="warning" color="#828282"></svg-icon>
        <div class="body4-medium sub3">추가 견적에 대한 금액은 추가 견적 확인 이후에 별도로 청구됩니다.</div>
      </div>
    </div>
    <div class="mobile">
      <template v-if="ordering">
        <div class="h8 main margin-bottom-16">추가 수정 요청 주문서</div>
        <div class="body5 sub3">기본 스킨에서 수정하고 싶은 기능 / 디자인에 대해 입력해주세요.</div>
      </template>
      <div class="margin-top-20 margin-bottom-4 body4-medium main">수정 요청 종류*</div>
      <div class="dropdown unselect sub" :class="{'dropdown-open':vDrop}" @click="vDrop = !vDrop">
        <div class="flex-between">
          <div class="dropdown-selected-item">{{ selectedLabel }}</div>
          <i class="material-icons">keyboard_arrow_{{ vDrop ? 'up' : 'down' }}</i>
        </div>
        <div class="dropdown-list" v-if="vDrop">
          <div class="dropdown-item" v-for="(item,idx) in categories" :key="`drop-${idx}`"
               @click.stop.prevent="clickItem(item)">{{ item.label }}</div>
        </div>
      </div>
      <div class="margin-top-20 margin-bottom-4 body4-medium main">수정 요청 내용*</div>
      <textarea v-model="value.content" @keyup="e => value.content = e.target.value"></textarea>
      <div class="margin-top-20 margin-bottom-4 body4-medium main">참고 파일</div>
      <input-file style="margin-bottom:16px" :value.sync="value.imgs"></input-file>

      <div v-if="ordering" class="box-warning">
        <svg-icon icon="warning" color="#828282"></svg-icon>
        <div class="body5-medium sub3">추가 견적에 대한 금액은 추가 견적 확인 이후에 별도로 청구됩니다.</div>
      </div>
    </div>
  </div>
</template>

<script>
  import InputFile from "../module/InputFile";
  import SvgIcon from "./SvgIcon";
  export default {
    name: "SkinInquiry",
    components: {SvgIcon, InputFile},
    props: {
      value: {
        type: Object
      },
      ordering: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        placeholder: '문의 유형을 선택해주세요.',
        categories: [
          { label: '디자인 수정', value: 1 },
          { label: '기능 수정', value: 2 },
          { label: '기능 추가', value: 3 }
        ],
        vDrop: false
      }
    },
    computed: {
      selectedLabel() {
        let cat = this.categories.find(i => i.value === this.value.category_id);
        return cat ? cat.label : this.placeholder;
      }
    },
    methods: {
      clickItem(item) {
        this.value.category_id = item.value;
        this.vDrop = false;
      },
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .dropdown
    position relative
    border 1px solid $gray1
    border-radius 8px
    background-color white

  .dropdown-open
    border-bottom-left-radius 0
    border-bottom-right-radius 0

  .dropdown
  .dropdown-item
    padding 12px 16px
    font-size 15px

  .dropdown-item:hover
    background-color $gray4

  .dropdown-list
    position absolute
    background-color white
    z-index 1
    border 1px solid $gray1
    border-bottom-left-radius 8px
    border-bottom-right-radius 8px
    top 47px
    left -1px
    width calc(100% + 2px)

  textarea
    padding 12px 16px
    border 1px solid $gray1
    height 166px
    width 100%
    border-radius 8px

  .box-warning
    display flex
    background-color $gray3
    align-items center
    padding 12px 16px
    border-radius 8px
    gap 16px
</style>
